<template>
<div>
  <select v-model="inputVal" class="select" name="" id="" @change="onChange">
    <!-- <option value="" selected>{{ initialOption }}</option> -->
    <option
      v-for="(option, indeX) in options"
      :value="option.value"
      :key="indeX"
      :disabled="option.disabled"
      :hidden="option.hidden"
      :selected="option.selected"
    >
      {{ option.text }}
    </option>
  </select>
</div>
</template>

<script>
export default {
  props: ["options", "value", "onChange"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.select {
  width: 100%;
  border: none;
  background: #ececec;
  background-image: url("~@/assets/icons/icon-down.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  height: 64px;
  border-radius: 16px;
  padding-left: 24px;
  appearance: none;
}
</style>